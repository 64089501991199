<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Mes a consultar"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
            color="primary"
            :max="new Date().toISOString().substr(0, 7)"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar</v-btn>
            <v-btn text @click="$refs.menu.save(date)"> OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card id="ventas-producto">
          <v-toolbar flat>
            <v-toolbar-title> Ausencias Diarias</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3 primary" />
          <v-card-text class="pa-3" style="height: auto">
            <v-chart
              ref="chartDiaria"
              theme="walden"
              class="ma-auto"
              style="width: 100%"
              :options="chartDiaria"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card id="ventas-acumuladas">
          <v-toolbar flat>
            <v-toolbar-title> Ausencias Acumuladas</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3 primary" />
          <v-card-text class="pa-3" style="height: auto">
            <v-chart
              ref="chartAcumulada"
              theme="walden"
              class="ma-auto"
              style="width: 100%"
              :options="chartAcumulada"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";

ECharts.registerTheme("walden", theme);

export default {
  name: "Faltas",
  components: {
    "v-chart": ECharts,
  },
  data: (vm) => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    chartAcumulada: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          let total = params.reduce((tot, b) => tot + b.value, 0);
          //(total);

          for (let i = 0; i < params.length; i++) {
            let perc = ((params[i].value / total) * 100).toFixed(2);
            perc = isNaN(perc) ? 0 : perc;

            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              vm.$options.filters.numberFormat(params[i].value) +
              " ( " +
              perc +
              "% )"; // : every 2nth

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          output +=
            "<br/><br/>Total: " + vm.$options.filters.numberFormat(total);
          return output;
        },
      },
      legend: {
        data: ["Ausencias", "Vacaciones", "Incapacidades"],
      },
      // grid: {
      //   left: '3%',
      //   right: '4%',
      //   bottom: '3%',
      //   containLabel: true
      // },
      xAxis: {
        type: "category",
        data: [],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Vacaciones",
          type: "line",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Incapacidades",
          type: "line",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Ausencias",
          type: "line",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
    chartDiaria: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          let total = params.reduce((tot, b) => tot + b.value, 0);

          for (let i = 0; i < params.length; i++) {
            let perc = ((params[i].value / total) * 100).toFixed(2);
            perc = isNaN(perc) ? 0 : perc;

            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              vm.$options.filters.numberFormat(params[i].value) +
              " ( " +
              perc +
              "% )"; // : every 2nth

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          output +=
            "<br/><br/>Total: " + vm.$options.filters.numberFormat(total);
          return output;
        },
      },
      legend: {
        data: ["Vacaciones", "Incapacidad", "Ausencias"],
      },
      xAxis: {
        type: "category",
        data: [],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Ausencias",
          stack: "total",
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Incapacidad",
          stack: "total",
          type: "bar",
          // barGap: "-100%",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Vacaciones",
          stack: "total",
          type: "bar",
          // barGap: "-100%",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
  }),
  computed: {},
  watch: {
    date: function () {
      this.getAbsenceReport();
    },
  },
  mounted() {
    this.$refs.chartDiaria.chart.showLoading("default", {
      text: "Cargando Datos",
    });
    this.$refs.chartAcumulada.chart.showLoading("default", {
      text: "Cargando Datos",
    });
    this.getAbsenceReport();
  },
  methods: {
    getAbsenceReport() {
      // this.clientsLoading = true;
      this.$refs.chartDiaria.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      this.$refs.chartAcumulada.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      this.$http
        .post("human_resource/getAbsenceReport", {
          date: this.date,
        })
        .then((res) => {
          this.$nextTick(() => {
            this.$refs.chartDiaria.chart.setOption({
              xAxis: {
                type: "category",
                data: res.data.days,
              },
              series: [
                {
                  name: "Ausencias",
                  data: res.data.ausencias,
                },
                {
                  name: "Incapacidad",
                  data: res.data.incapacidad,
                },
                {
                  name: "Vacaciones",
                  data: res.data.vacaciones,
                },
              ],
            });
            this.$refs.chartAcumulada.chart.setOption({
              xAxis: {
                type: "category",
                data: res.data.days,
              },
              series: [
                {
                  name: "Ausencias",
                  data: res.data.ausenciasC,
                },
                {
                  name: "Incapacidad",
                  data: res.data.incapacidadC,
                },
                {
                  name: "Vacaciones",
                  data: res.data.vacacionesC,
                },
              ],
            });
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$refs.chartDiaria.chart.hideLoading();
          this.$refs.chartAcumulada.chart.hideLoading();
        });
    },
  },
};
</script>

<style scoped></style>
